<!--  -->
<template>
  <div class="classify">
    <mescroll :up="mescrollUp" :down="mescrollDown">
      <div slot="scrollList" class="scrollList">
        <van-sticky>
          <div class="sticky_top">
            <!--搜索-->
            <!--          <search :searchOut="searchOut" v-show="!this.$route.query.brand == 11"></search>-->

            <search v-if="this.$route.query.brand == 33" :search-out="searchOut" />
            <search v-else :search-out="headGo" />
            <!--导航  tab 切换只有 悦淘国际 首页 更多-->
            <div v-show="this.$route.query.brand == 66" class="tab_vant">
              <van-tabs
                v-model="active"
                title-active-color="#E33F44"
                title-inactive-color="#333"
                color="#fff"
              >
                <van-tab v-for="(item,index) in tabType" :key="index">
                  <template #title>
                    <div @click="tabList(item)">{{ item.categoryName }}</div>
                  </template>
                </van-tab>
              </van-tabs>
            </div>
            <!--导航 4 个-->
            <filterTabs :options="options" />
            <!--tab （channelId 的切换） -->
            <!--brand  为 11 的时候   才显示  这个为 数码家电 母婴玩具 日用家局 页面公用-->
            <div v-show="this.$route.query.brand == 11" class="channel_all_box">
              <div v-for="(item,index) in channelIdList" :key="index" class="channel_all" @click="btnChannelId(item)">
                <div class="channel_son" :class="isIndex==index ? 'bf' : ''" @click="isIndex=index">{{ item.title }}
                </div>
              </div>
            </div>
          </div>
        </van-sticky>
        <!--循环 list -->
        <div v-for="(ele, index) in shoplist" :key="index" class="shoplist" @click="godtal(ele)">
          <div style="position: relative">
            <!--            <div class="shoplist_name">{{ele.channelName}}</div>-->
            <imgLoading :src="ele.productImage" class="img" />
          </div>

          <div class="shoptext">
            <div class="shoptitle">{{ ele.productName }}</div>
            <div>
              <div>
                <span style="color: red; font-size: 10px">￥</span>
                <span style="color: red; font-size: 18px">{{ ele.vipPrice }}</span>
                <span style="text-decoration: line-through;">{{ ele.channelName }}￥{{ ele.price }}</span>
              </div>
              <!--              <div>热销{{ ele.orderPayCount }}件</div>-->
            </div>
          </div>
        </div>
        <!--暂无数据-->
        <div v-if="shoplist.length==0" class="no_nub">
          <div id="dataList" />
          暂无数据
        </div>
      </div>
    </mescroll>
    <!--筛选-->
    <rightSidebar :data-sxlist="dataSxlist" />
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import filterTabs from '@/component/filterTabs/index'
import search from '@/component/head/search'
import mescroll from '@/component/fresh_scroll/mescroll'
import imgLoading from '@/component/imgLoading'
import rightSidebar from '@/component/filterTabs/rightSidebar'

import Vue from 'vue'
import { optionskeyword, getscreen, getXySearch, getXyCateGoods } from '@/services/api'
import { Button, Lazyload, Popup, Sticky, Tab, Tabs, Toast } from 'vant'

Vue.use(Tab).use(Tabs).use(Sticky).use(Popup).use(Button).use(Toast).use(Lazyload)
export default {
  // import引入的组件需要注入到对象中才能使用
  name: 'SideBar',
  components: {
    search,
    filterTabs,
    mescroll,
    imgLoading,
    rightSidebar
  },
  data() {
    // 这里存放数据
    return {
      dataSxlist: {
        dataList: '', //  侧边栏 接口 数据
        isShow: false, // 侧边栏 显示隐藏
        btnDetermine: this.btnDetermine // 点击确定回调
      },
      active: '',
      tabType: '', // tab list
      channelIdList: '', // channelId  all 1 2 3
      isIndex: 0, // tab 下标
      copylist: [],
      options: {
        active: 0,
        activeChange: (index) => {
          this.parmas.page = 1
          this.sxparmas.sorttype = index + 1
          this.parmas.sorttype = index + 1
          // tab 切换
          if (this.$route.query.brand === 44 && this.$route.query.brand === 55) {
            // 44 为 悦淘国际 的 首页 4个 img
            getXySearch(this.parmas).then((res) => {
              this.shoplist = res.data.data
            })
          } else if (this.$route.query.brand === 66) {
            //  brand 66 （为 悦淘国际 首页 更多）
            getXyCateGoods(this.parmas).then((res) => {
              this.shoplist = res.data.data
              this.tabType = res.data.type
            })
          } else {
            // 其他接口 公共的
            optionskeyword(this.parmas).then((res) => {
              this.shoplist = res.data.doc
              this.channelIdList = res.data.menu
            })
          }
        },
        tabsList: [
          {
            title: '综合',
            sort: {
              showSort: false,
              sortTag: '',
              sortChange: (type) => {
                this.typeChange(type)
              }
            }
          },
          {
            title: '销量',

            sort: {
              showSort: false,
              sortTag: '',
              sortChange: (type) => {
                this.typeChange(type)
              }
            }
          },
          {
            title: '价格',
            sort: {
              showSort: true,
              sortTag: '',
              sortChange: (type) => {
                this.typeChange(type)
              }
            }
          },
          {
            title: '筛选',
            filter: {
              showFilter: true,
              filterChange: () => {
                this.dataSxlist.isShow = true
                getscreen(this.parmas).then((res) => {
                  this.dataSxlist.dataList = res.data
                })
              }
            }
          }
        ]
      },
      // 又来承接的数组
      orderlist: [],
      didx: 1,
      sxlist: '',
      shoplist: [],
      show: false,
      type: true,
      sortType: 0,
      headGo: {
        isShowSearch: false, // 输入框 title 控制
        isShowReturn: true, // 返回 上一页 显隐
        bgColor: '#fff', // 背景颜色
        titleData: this.$route.query.keyword,
        callbackGo: this.callbackGo
      },
      searchOut: {
        isShowSearch: true,
        searchWidth: '76%',
        searchBorderColor: 'red',
        titleData: '', // 设置标题 title 名字
        bgColor: '#fff', // 设置导航背景颜色
        urlImg: '', // 点击返回图片 可以设置
        isShowReturn: true, // 点击返回图片 显隐
        textColor: '#000',
        callbackGo: this.callbackGo, // 返回回调函数
        callbackClick: this.callbackClick // 搜索跳转
      },
      // 列表需要的参数
      parmas: {
        indexKey: '',
        channelId: '',
        categoryFirstId: '',
        categoryThreeId: '',
        keyword: '',
        sorttype: 1, //  1 全部  2 销量  3 价格
        sort: 0, // 1价格正序  0 倒序
        page: 1,
        pageSize: 10,
        id: ''
      },
      sxparmas: {
        indexKey: '',
        channelId: 'all',
        categoryFirstId: '',
        categorySecondId: '',
        categoryThreeId: '',
        keyword: '',
        sorttype: 1, //  1 全部  2 销量  3 价格
        sort: 0, // 1价格正序  0 倒序
        page: 1,
        pageSize: 10,
        minprice: '',
        maxprice: '',
        brandId: ''
      },
      mescrollDown: {
        use: true,
        bottom: '0'
      },
      mescrollUp: {
        use: true,
        initCallBack: this.mescrollInit,
        callback: this.upCallback, // 上拉回调,此处可简写; 相当于 callback: function (page, mescroll) { getListData(page); }
        page: {
          num: 0, // 当前页码,默认0,回调之前会加1,即callback(page)会从1开始
          size: 10 // 每页数据的数量
        },
        noMoreSize: 5, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
        lazyLoad: {
          use: true // 是否开启懒加载,默认false
        }
      }
    }
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    const { brand, keyword } = this.$route.query
    document.title = keyword || ''
    this.searchOut.titleData = keyword
    this.parmas.keyword = keyword
    this.parmas.brand = Number(brand)
    this.initialVal(Number(brand))
    this.initialList(Number(brand))
    this.initialSidebar(Number(brand))
  },
  // 方法集合
  methods: {

    // list 列表
    initialList: function(brand) {
      // brand 55 （为 悦淘国际 首页 轮播img 新接口） brand 44  （为 悦淘国际 首页 四个 img 新接口）
      if (brand === 44 || brand === 55) {
        getXySearch(this.parmas).then((res) => {
          this.shoplist = res.data.data
        })
      } else if (brand === 66) {
        //  brand 66 （为 悦淘国际 首页 更多）
        getXyCateGoods(this.parmas).then((res) => {
          this.shoplist = res.data.data
          this.tabType = res.data.type
        })
      } else {
        // 其他接口 公共的（考拉接口）
        optionskeyword(this.parmas).then((res) => {
          this.shoplist = res.data.doc
          this.channelIdList = res.data.menu
        })
      }
    },
    // 侧边栏 接口
    initialSidebar: function(brand) {
      if (brand === 44) {
        // brand 44  为 悦淘国际 （上边 四个 img 头部）
        this.parmas.indexKey = 'importType'
        this.parmas.keyword = 'importType'
        this.parmas.importType = this.$route.query.goodTypeId
      } else if (brand === 55) {
        // brand 55  为 悦淘国际 下边轮播图
        this.parmas.indexKey = 'productArea'
        this.parmas.keyword = 'productArea'
        this.parmas.productArea = this.$route.query.goodTypeId
      } else {
        // 其他 页面（考拉公共） 搜索
        getscreen(this.parmas).then((res) => {
          this.dataSxlist.dataList = res.data
        })
      }
    },

    // 页面初始化 参数
    initialVal: function(brand) {
      if (brand === 11) {
        // 11 为 母婴玩具， 家用电器 ，日用家居 页面
        this.parmas.channelId = 3 // 默认 3
        this.parmas.keyword = this.$route.query.keyword
      } else if (brand === 22) {
        // 22 为 悦淘国际 的 品牌推荐 里的 品牌墙
        this.parmas.channelId = 7 // 默认 3
        this.parmas.keyword = this.$route.query.keyword
        this.parmas.brandId = this.$route.query.brandId
      } else if (brand === 33) {
        // 33 为 悦淘国际 的 品牌推荐
        this.parmas.channelId = 7
        this.parmas.indexKey = 'brandId'
        this.parmas.brandId = this.$route.query.goodTypeId
      } else if (brand === 44) {
        // 44 为 悦淘国际 的 首页 4个 img
        this.parmas.channelId = this.$route.query.channelId
        this.parmas.keyword = 'importType'
        this.parmas.id = this.$route.query.goodTypeId
        this.parmas.indexKey = 'importType'
      } else if (brand === 55) {
        // 55 为 悦淘国际 的 首页 下边轮播图
        this.parmas.channelId = this.$route.query.channelId
        this.parmas.keyword = 'productArea'
        this.parmas.id = this.$route.query.goodTypeId
        this.parmas.indexKey = 'productArea'
      } else if (brand === 66) {
        this.parmas.keyword = 'categoryFirstId'
        this.parmas.categoryFirstId = 101997 // 默认    101997 更多点击去的
        this.parmas.indexKey = 'categoryFirstId'
        this.parmas.channelId = 7
      }
    },

    // 侧边栏 回调
    btnDetermine: function(data) {
      // 公共 的
      const publicData = {
        sorttype: data.sorttype, // 1 全部  2 销量  3 价格
        sort: data.sort, // 1 价格正序  0 倒序
        page: data.page, // 第几页
        pageSize: data.pageSize, // 10 条
        minprice: data.minprice, // 最小 价格
        maxprice: data.maxprice // 最大 价格
      }
      if (this.$route.query.brand === 11) {
        publicData.channelId = 3
        publicData.categoryFirstId = ''
        publicData.categoryThreeId = data.categoryThreeId
        publicData.keyword = this.$route.query.keyword
        publicData.brandId = data.brandId
      } else if (this.$route.query.brand === 22) {
        // 品牌墙
        publicData.channelId = 7
        publicData.categoryFirstId = data.categoryFirstId
        publicData.categoryThreeId = ''
        publicData.keyword = this.$route.query.keyword
        publicData.brandId = this.$route.query.brandId
      } else if (this.$route.query.brand === 33) {
        // 33 为 悦淘国际 的 品牌推荐
        publicData.indexKey = 'brandId'
        publicData.channelId = 7
        publicData.categoryFirstId = data.categoryFirstId
        publicData.categoryThreeId = ''
        publicData.keyword = 'brandId'
        publicData.brandId = data.brandId
      } else if (this.$route.query.brand === 44) {
        // 44 为 悦淘国际 的 首页 4个 img
        publicData.indexKey = 'importType'
        publicData.channelId = this.$route.query.channelId
        publicData.categoryFirstId = data.categoryFirstId
        publicData.categoryThreeId = ''
        publicData.keyword = 'importType'
        publicData.id = this.$route.query.goodTypeId
        publicData.importType = this.$route.query.goodTypeId
        publicData.brandId = data.brandId
        getXySearch(publicData).then((res) => {
          this.shoplist = res.data.data
        })
      } else if (this.$route.query.brand === 55) {
        // 55 为 悦淘国际 的 首页 轮播图
        publicData.indexKey = 'productArea'
        publicData.channelId = this.$route.query.channelId
        publicData.categoryFirstId = data.categoryFirstId
        publicData.categoryThreeId = ''
        publicData.keyword = 'productArea'
        publicData.id = this.$route.query.goodTypeId
        publicData.importType = this.$route.query.goodTypeId
        publicData.brandId = data.brandId
        getXySearch(publicData).then((res) => {
          this.shoplist = res.data.data
        })
      } else if (this.$route.query.brand === 66) {
        //  brand 66 （为 悦淘国际 首页 更多）
        publicData.indexKey = 'categoryFirstId'
        publicData.channelId = 7
        publicData.categoryFirstId = data.categoryFirstId
        publicData.categoryThreeId = ''
        publicData.keyword = 'categoryFirstId'
        publicData.brandId = data.brandId
        getXyCateGoods(publicData).then((res) => {
          this.shoplist = res.data.data
          this.tabType = res.data.type
        })
      }
      // 公共的
      if (this.$route.query.brand === 11 || this.$route.query.brand === 22 || this.$route.query.brand === 33) {
        optionskeyword(publicData).then((res) => {
          this.shoplist = res.data.doc
          this.channelIdList = res.data.menu
        })
      }
    },
    // 点击导航tab 切换
    tabList: function(id) {
      this.parmas.categoryFirstId = id.categoryId
      getXyCateGoods(this.parmas).then((res) => {
        this.shoplist = res.data.data
      })
    },
    // 点击 tab 切换  ChannelId   三级分类
    btnChannelId: function(t) {
      this.parmas.channelId = t.channelid
      if (this.$route.query.brand === 44 || this.$route.query.brand === 55) {
        // 44 为 悦淘国际 的 首页 4个 img
        getXySearch(this.parmas).then((res) => {
          this.shoplist = res.data.data
        })
      } else if (this.$route.query.brand === 66) {
        //  brand 66 （为 悦淘国际 首页 更多）
        getXyCateGoods(this.parmas).then((res) => {
          this.shoplist = res.data.data
          this.tabType = res.data.type
        })
      } else {
        // 公共的接口（考拉公共）
        optionskeyword(this.parmas).then((res) => {
          this.shoplist = res.data.doc
        })
      }
    },
    // 返回
    callbackGo() {
      this.$router.go(-1)
    },
    // 点击到搜索
    callbackClick: function() {
      this.$router.push({
        path: '/goodsSearch'
      })
    },
    // 下拉刷新
    mescrollInit(data) {
      this.mescroll = data
    },
    upCallback(page, mescroll) {
      // 接口请求数据
      this.getListDataFromNet(
        this.pdType,
        page.num,
        page.size,
        (arr) => {
          // 如果是第一页需手动制空列表
          if (page.num === 1) this.shoplist = []
          // 把请求到的数据添加到列表
          this.shoplist = this.shoplist.concat(arr)
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            // let number=0
            // if(arr.length>0){
            //   number=11
            // }
            mescroll.endSuccess(arr.length)
          })
        },
        () => {
          // 联网异常,隐藏上拉和下拉的加载进度
          mescroll.endErr()
        }
      )
    },
    getListDataFromNet(pdType, pageNum, pageSize, successCallback, errorCallback) {
      // brand 为上一个页面传过来的 11 为
      if (this.$route.query.brand === 11) {
        this.parmas.page = pageNum
        this.parmas.pageSize = pageSize
      } else if (this.$route.query.brand === 22) {
        // 22 为 悦淘国际 的 品牌推荐 里的 品牌墙
        this.parmas.page = pageNum
        this.parmas.pageSize = pageSize
        this.parmas.brandId = this.$route.query.brandId
      } else if (this.$route.query.brand === 33) {
        // 33 为 悦淘国际 的 品牌推荐
        this.parmas.channelId = 7
        this.parmas.indexKey = 'brandId'
        this.parmas.brandId = this.$route.query.goodTypeId
      } else {
        this.parmas.page = pageNum
        this.parmas.pageSize = pageSize
      }
      if (this.$route.query.brand === 44) {
        // 44 为 悦淘国际 的 首页 4个 img
        getXySearch(this.parmas).then((res) => {
          successCallback(res.data.data)
        })
      } else if (this.$route.query.brand === 55) {
        // 55 为 悦淘国际 的 轮播 img
        getXySearch(this.parmas).then((res) => {
          successCallback(res.data.data)
        })
      } else if (this.$route.query.brand === 66) {
        getXyCateGoods(this.parmas).then((res) => {
          successCallback(res.data.data)
        })
      } else {
        // 公共接口（考拉公共）
        optionskeyword(this.parmas).then((res) => {
          successCallback(res.data.doc)
        })
      }
    },
    // 点击 list 跳详情
    godtal(ele) {
      const obj = {
        'product_type': ele.product_type,
        'goodId': ele.productId,
        'skuId': ele.skuId
      }
      this.$store.dispatch('godetail', obj)
    },
    // 价格 排序方法
    typeChange(type) {
      if (type === 'add') {
        this.parmas.sort = 1
      } else {
        this.parmas.sort = 0
      }
      if (this.$route.query.brand === 44 || this.$route.query.brand === 55) {
        // 44 为 悦淘国际 的 首页 4个 img 55 为 悦淘国际 的 首页 轮播图
        getXySearch(this.parmas).then((res) => {
          // this.shoplist = res.data.doc;
          this.shoplist = res.data.data
        })
      } else if (this.$route.query.brand === 66) {
        //  brand 66 （为 悦淘国际 首页 更多）
        getXyCateGoods(this.parmas).then((res) => {
          this.shoplist = res.data.data
          this.tabType = res.data.type
        })
      } else {
        // 公共接口
        optionskeyword(this.parmas).then((res) => {
          this.shoplist = res.data.doc
          this.channelIdList = res.data.menu
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.classify {
  * {
    text-align: left;

    h1 {
      font-size: 12px;
      padding-left: 10px;
    }

    .no_nub {
      text-align: center;
      padding: 12px;
      box-sizing: border-box;
      background: #fff;
    }
  }

  .scrollList {
    background: #f6f6f6;

    .tab_vant {
      width: 100%;
    }

    .channel_all_box {
      background: #fff;
      margin-top: -1px;

      .channel_all {
        display: inline-block;
        padding: 12px 0;
        box-sizing: border-box;

        .channel_son {
          margin-left: 12px;
        }

        .channel_son.bf {
          color: red;
        }
      }
    }
  }

  .pxarrow {
    width: 12px;
    height: 16px;
    margin-left: 2px;
  }

  .shoplist {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    overflow: hidden;
    background: #fff;
    position: relative;

    .shoplist_name {
      position: absolute;
      right: 0;
      top: 10px;
      background: #d61919;
      padding: 2px 6px;
      font-size: 12px;
      color: #fff;
      border-radius: 10px 0 0 10px;
    }

    .img {
      width: 100px;
      height: 100px;
    }

    .shoptext {
      padding-left: 10px;
      width: 250px;
      height: 90px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 12px;

      div {
        font-size: 10px;
      }

      .shoptitle {
        line-height: 20px;
        width: 100%;
        height: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  .zzbox {
    padding-top: 25px;
    width: 300px;
    height: 100%;
    overflow-x: hidden;
    position: relative;
    box-sizing: border-box;

    .all_box {
      height: calc(100% - 40px);
      width: 100%;
      overflow-x: hidden;
      overflow-y: scroll;
    }

    .btnbox {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      background: #ffffff;

      button {
        width: 45%;
        height: 30px;
        text-align: center;
        line-height: 30px;
        border-radius: 15px;
      }

      .btn2 {
        color: #ffffff;
        background: red;
      }
    }

    h1 {
      margin: 5px 0;
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 5px;

      .lis {
        margin-top: 5px;
        margin-left: 3%;
        height: 25px;
        line-height: 25px;
        background: #f8f8f8;
        border-radius: 15px;
        text-align: center;
        width: 28%;
        padding: 0 10px;
        font-size: 12px;
        overflow: hidden;
      }

      .li {
        width: 33%;
        //flex: 1;
        margin-top: 5px;
        height: 25px;
        line-height: 25px;
        background: #f8f8f8;
        border-radius: 15px;
        text-align: center;
        font-size: 12px;

        span {
          display: inline-block;
          width: 100%;
          white-space: nowrap;
          text-align: center;
          transform: scale(0.6);
        }
      }

      .bgipt {
        color: rgb(236, 185, 185);
        background: rgb(250, 238, 238);
        border: 1px solid rgb(236, 185, 185);
      }
    }
  }

  .close {
    display: flex;
    align-items: center;
    justify-content: space-around;

    .ipt {
      width: 100px;
      border-radius: 10px;
      height: 25px;
      background: #f8f8f8;
      text-align: center;
    }

    div {
      width: 20px;
      height: 1px;
      background: #eeeeee;
    }

  }
}
</style>

